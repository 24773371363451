import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";
import QrCodeIcon from '@mui/icons-material/QrCode';
import FinanceiroTicketModal from "../FinanceiroTicketModal";
import ContratoTicketModal from "../ContratoTicketModal";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import DescriptionIcon from '@mui/icons-material/Description'; // Ícone genérico de contrato
import Tooltip from '@mui/material/Tooltip'; // Import do Tooltip



const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));



const TicketActionButtons = ({ ticket, handleClose, isMounted }) => {
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [financeiroTicketModalOpen, setFinanceiroTicketModalOpen] = useState(false);
	const [contratoTicketModalOpen, setContratoTicketModalOpen] = useState(false);

	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);



	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};




	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};



	const handleCloseFinanceiroTicketModal = () => {
		setFinanceiroTicketModalOpen(false);
	};


	const handleCloseContratoTicketModal = () => {
		setContratoTicketModalOpen(false);
	};


	const handleOpenContratoModal = e => {
		setContratoTicketModalOpen(true);
		handleClose();
	};


	const handleOpenFinanceiroModal = e => {
		setFinanceiroTicketModalOpen(true);
		handleClose();
	};


	return (


		<div className={classes.actionButtons}>

			{ticket.status === "open" && !ticket.isGroup && (
				<>
									<Tooltip title={i18n.t("Gerar PIX")} arrow>
						<span>
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="info"
								onClick={handleOpenFinanceiroModal}
								sx={{
									minWidth: 0, // Remove a largura mínima padrão do botão
									width: '27px', // Define a largura do botão de acordo com o ícone
									height: '27px', // Define a altura do botão de acordo com o ícone
									padding: '0px', // Remove qualquer padding interno do botão
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{/* Logotipo do PIX usando SVG */}
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
									<path fill="#4db6ac" d="M11.9,12h-0.68l8.04-8.04c2.62-2.61,6.86-2.61,9.48,0L36.78,12H36.1c-1.6,0-3.11,0.62-4.24,1.76	l-6.8,6.77c-0.59,0.59-1.53,0.59-2.12,0l-6.8-6.77C15.01,12.62,13.5,12,11.9,12z"></path>
									<path fill="#4db6ac" d="M36.1,36h0.68l-8.04,8.04c-2.62,2.61-6.86,2.61-9.48,0L11.22,36h0.68c1.6,0,3.11-0.62,4.24-1.76	l6.8-6.77c0.59-0.59,1.53-0.59,2.12,0l6.8,6.77C32.99,35.38,34.5,36,36.1,36z"></path>
									<path fill="#4db6ac" d="M44.04,28.74L38.78,34H36.1c-1.07,0-2.07-0.42-2.83-1.17l-6.8-6.78c-1.36-1.36-3.58-1.36-4.94,0	l-6.8,6.78C13.97,33.58,12.97,34,11.9,34H9.22l-5.26-5.26c-2.61-2.62-2.61-6.86,0-9.48L9.22,14h2.68c1.07,0,2.07,0.42,2.83,1.17	l6.8,6.78c0.68,0.68,1.58,1.02,2.47,1.02s1.79-0.34,2.47-1.02l6.8-6.78C34.03,14.42,35.03,14,36.1,14h2.68l5.26,5.26	C46.65,21.88,46.65,26.12,44.04,28.74z"></path>
								</svg>
							</ButtonWithSpinner>
						</span>
					</Tooltip>

					<Tooltip title={i18n.t("Agendar Mensagem")} arrow>
						<span>
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="info"
								onClick={handleOpenFinanceiroModal}
								sx={{
									minWidth: 0, // Remove a largura mínima padrão do botão
									width: '27px', // Define a largura do botão de acordo com o ícone
									height: '27px', // Define a altura do botão de acordo com o ícone
									padding: '0px', // Remove qualquer padding interno do botão
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{/* Logotipo do PIX usando SVG */}
								<img width="20" height="20" src="https://img.icons8.com/fluency/48/schedule-mail.png" alt="schedule-mail"/>	
								</ButtonWithSpinner>
						</span>
					</Tooltip>


					<Tooltip title={i18n.t("Gerar Contrato")} arrow>
						<span>
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="info"
								onClick={handleOpenContratoModal}
								sx={{
									minWidth: 0, // Remove a largura mínima padrão do botão
									width: '27px', // Define a largura do botão de acordo com o ícone
									height: '27px', // Define a altura do botão de acordo com o ícone
									padding: '0px', // Remove qualquer padding interno do botão
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{/* Logotipo do PIX usando SVG */}
								<img width="20" height="20" src="https://img.icons8.com/fluency/48/petition.png" alt="petition"/>							
								</ButtonWithSpinner>
						</span>
					</Tooltip>



					<Tooltip title={i18n.t("Finalizar Atendimento")} arrow>
						<span>
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="info"
								onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
								sx={{
									minWidth: 0, // Remove a largura mínima padrão do botão
									width: '27px', // Define a largura do botão de acordo com o ícone
									height: '27px', // Define a altura do botão de acordo com o ícone
									padding: '0px', // Remove qualquer padding interno do botão
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{/* Logotipo do PIX usando SVG */}
								<img width="20" height="20" src="https://img.icons8.com/fluency/48/end-call.png" alt="end-call"/>							
								</ButtonWithSpinner>
						</span>
					</Tooltip>



					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}



					/>
					<FinanceiroTicketModal
						modalOpen={financeiroTicketModalOpen}
						onClose={handleCloseFinanceiroTicketModal}
						ticketid={ticket.id}
					/>


					<ContratoTicketModal
						modalOpen={contratoTicketModalOpen}
						onClose={handleCloseContratoTicketModal}
						ticketid={ticket.id}
					/>


				</>


			)}
		</div>
	);
};

export default TicketActionButtons;
